import axios from 'axios'

/** Contact Us form submission */
// const endpoint = 'http://127.0.0.1:8787'
// const endpoint = 'https://contact-us-staging.panacea.workers.dev'
const endpoint = 'https://contact-us.panaceaprime.net'

const formEl = document.getElementById('contact-us-form')
formEl.addEventListener('submit', evt => {
  evt.preventDefault()
  const loading = 'loading'
  const success = 'success'
  const error = 'error'

  formEl.classList.remove(loading)
  formEl.classList.remove(success)
  formEl.classList.remove(error)

  formEl.classList.add(loading)
  axios.post(endpoint, new FormData(document.forms['contact-us-form']))
    .then(() => {
      formEl.classList.add(success)
      formEl.classList.remove(loading)
      setTimeout(() => {
        formEl.classList.remove(success)
      }, 4000)
    })
    .catch(() => {
      formEl.classList.remove(loading)
      formEl.classList.add(error)
    })
})

const mainEl = document.querySelector('main')

// /** Shrink navbar on scroll */
// mainEl.addEventListener('scroll', () => {
//   const threshold = 80
//   const shrinkClass = 'shrink'

//   const header = document.querySelector('header')
//   if (mainEl.scrollTop > threshold) {
//     header.classList.add(shrinkClass)
//   } else {
//     header.classList.remove(shrinkClass)
//   }
// })

/** Navbar intersection observers */
const navbarSections = [
  'home',
  'about-us',
  'product',
  'contact-us',
  'privacy-policy'
]

const navbarCb = (entries: IntersectionObserverEntry[]) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      document.querySelectorAll('.--nav-link.--active').forEach(el => {
        el.classList.remove('--active')
      })
      document.querySelectorAll(`.--nav-link[href="#${entry.target.id}"]`).forEach(el => {
        el.classList.add('--active')
      })
    }
  })
}

const navbarOpts = {
  root: mainEl,
  rootMargin: '0px',
  threshold: 0.5
}

const navbarObserver = new IntersectionObserver(navbarCb, navbarOpts)

navbarSections.forEach(id => {
  navbarObserver.observe(document.getElementById(id))
})

/** Section active intersection observers */
const activeSections = [
  'about-us',
  'contact-us'
]

const activeCb = entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('active')
    }
  })
}

const activeOpts = {
  root: mainEl,
  rootMargin: '0px',
  threshold: 0.3
}

const activeObserver = new IntersectionObserver(activeCb, activeOpts)

activeSections.forEach(id => {
  activeObserver.observe(document.getElementById(id))
})

/** Product active intersection observers */
document.querySelectorAll('.--product-item').forEach(el => {
  activeObserver.observe(el)
})
